@import 'styles/main';

.account-icon {
  @include media-breakpoint-up(xs) {
    margin-right: $spacing-xxs;
  }
}

.profile-btn {
  position: relative;
  align-items: center;
  padding: 0 10px;

  :global(.plc-dropdown) {
    @include z-index(modal);
    background: $grey-cloud-light;
    top: 52px;
    right: 10px;
    padding-top: $spacing-s;
    li:not(:last-child) {
      a {
        display: block;
        padding: $spacing-s $spacing-m;
      }
    }
    li:last-child {
      border-top: 2px solid $grey;
      padding: $spacing-s;
    }
    .exit-icon {
      margin-left: $spacing-m;
    }
    .active {
      border-bottom: 2px solid $color-primary-1;
    }
    &:before {
      $size: 12px;
      content: '\25C6';
      position: absolute;
      top: -$size;
      font-size: 2 * $size;
      line-height: 2 * $size;
      right: 10px;
      color: $grey-cloud-light;
    }
  }
}