@import 'styles/main';

.header-button {
  background-color: $white;
  border: 2px solid $new-color-primary-1;
  padding: $spacing-s;
  border-radius: $spacing-xs;
  display: flex;
  align-items: center;

  &.is-active {
    background-color: $new-color-primary-1;
    &:hover {
      background-color: $new-color-primary-1-hover;
    }
  };

  &.non-active {
    &:hover {
      background-color: $grey-cloud-light;
    }
  }

  &.is-mobile {
    width: 100%;
    margin-bottom: $spacing-xxs;
  }
}

.banner-button {
  background-color: $white;
  width: 48%;
  border-radius: $spacing-xs;
  padding: $spacing-l $spacing-m;
  @include media-breakpoint-up(m) {
    width: 24%;
  }
  &:hover {
    background-color: $grey-cloud-light;
  }
}